.page-new-lead {
    padding: 40px;

    .new-lead {
        //
        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__header-wrapper {
            display: flex;
            align-items: center;
        }

        &__button-container {
            display: flex;
            align-items: center;

            .app-cta {
                margin: 0 0 0 10px;
                min-width: 150px;
            }
        }

        &__back-button {
            width: 35px;
            height: 35px;
            margin-right: 15px;
            color: $c-white;
            background-color: $c-primary;
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__title {
            margin: 0;
            color: $c-light-black;
            font-size: $text-size-xl;
            @extend .font-semibold;
        }

        &__card {
            min-width: 960px;
            padding: 25px;
            margin-top: 25px;
            border-radius: 15px;
            background-color: $c-white;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
        }

        &__form-option {
            width: 100%;

            .radio-input {
                padding-left: 0;
            }
        }

        &__field {
            width: calc(50% - 20px);
            margin: 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &--checkbox {
                width: 100%;
                height: 45px;
                justify-content: flex-start;

                input {
                    margin: 0 10px 0 0;
                }

                .new-lead__label {
                    width: auto;
                    font-style: italic;
                    font-size: $text-size-xs;
                    @extend .font-normal;
                }
            }

            .app-input,
            .app-dropdown,
            .app-radio-input,
            .app-date-input {
                margin: 8px 0;
                width: 250px;
            }
        }

        &__label {
            width: 50%;
            margin: 0;
            font-size: $text-size-s;
            @extend .font-semibold;
        }

        &__section {
            width: 100%;
            padding-top: 25px;
            margin: 25px 8px;
            color: $c-primary;
            font-size: $text-size-m;
            border-top: 1px dashed lighten($c-light-black, 75%);
            @extend .font-semibold;
        }

        &__description {
            width: 100%;
            margin: 0 8px;
            color: $c-gray;
            font-size: $text-size-xs;
            @extend .font-normal;
        }
    }
}
