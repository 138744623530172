.page-matrix-configure {
    position: relative;
    height: 100%;
    padding: 40px 0;

    .matrix-configure {
        height: 100%;

        &__header {
            padding: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__header-wrapper {
            display: flex;
            align-items: center;
        }

        &__button-container {
            display: flex;
            align-items: center;

            .app-cta {
                margin: 0 0 0 10px;
                min-width: 150px;
            }
        }

        &__back-button {
            width: 35px;
            height: 35px;
            margin-right: 15px;
            color: $c-white;
            background-color: $c-primary;
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__title {
            margin: 0;
            color: $c-light-black;
            font-size: $text-size-xl;
            @extend .font-semibold;
        }

        &__container {
            width: 100%;
            height: 100%;

            iframe {
                height: 100%;
                width: 100%;
            }
        }
    }
}
