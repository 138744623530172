.page-lead-maintence {
	padding: 40px;

	.lead-maintence {
		//
		&__title {
			margin: 0;
			color: $c-light-black;
			font-size: $text-size-xl;
			@extend .font-semibold;
		}

		&__header {
			width: 100%;
			padding: 20px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__description {
			margin: 0;
			color: $c-gray;
			font-size: $text-size-s;
		}

		&__search {
			display: flex;
			align-items: center;

			input {
				border: 0;
				outline: none;
				background-color: $c-light-gray;

				&::placeholder {
					color: $c-gray;
				}
			}

			svg {
				margin: 5px;
				width: 25px;
				height: 25px;
				flex-shrink: 0;
			}

			.app-cta {
				margin-left: 25px;
			}
		}
	}
}
