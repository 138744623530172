.app-nav-header {
	width: 100%;
	height: 80px;
	background-color: $c-primary;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	z-index: 999;

	.nav-header {
		height: 100%;
		padding: 10px 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__list {
			margin: 0;
			padding: 0;
			display: flex;
			align-items: center;
		}

		&__nav-item {
			padding: 2px 10px;
			list-style-type: none;
			display: flex;
			align-items: center;

			a {
				color: $c-white;
				text-decoration: none;
			}

			&--active {
				a {
					color: $c-light-navy;
					@extend .font-semibold;
				}
			}
		}

		&__item {
			padding: 2px 10px;
			list-style-type: none;
			display: flex;
			align-items: center;

			&:last-child {
				border-left: 1px solid $c-white;
			}
		}

		&__profile {
			width: 40px;
			height: 40px;
			border-radius: 100px;
			overflow: hidden;
			background-color: $c-dark-gray;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			flex-shrink: 0;
		}

		&__actions {
			display: flex;
			align-items: center;
			justify-content: center;

			button {
				width: 30px;
				height: 30px;
				margin: 0 5px;
				border: 0;
				border-radius: 100px;
				background-color: transparent;
				display: flex;
				align-items: center;
				justify-content: center;

				&:hover {
					background-color: darken($c-light-navy, 3%);
				}

				img {
					width: 100%;
				}
			}
		}
	}
}
