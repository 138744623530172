.app-cta {
	width: 100%;
	max-width: 320px;
	min-width: 250px;
	padding: 12.5px 25px;
	color: $c-white;
	text-align: center;
	border: 0;
	border-radius: 5px;
	background-color: $c-primary;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

	&:disabled {
		background-color: $c-light-gray;
	}

	&--cancel {
		background-color: $c-gray;
	}

	&--outline {
		color: $c-primary;
		background-color: $c-white;
		border: 1px solid $c-primary;
	}

	&--loading {
		&:disabled {
			background-color: $c-black;
		}
	}

	&__container {
		position: relative;
		display: inline-block;
		word-break: break-word;
	}

	&__spinner {
		position: absolute;
		left: -25px;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	&__label {
	}
}
