.page-sign-in {
	height: 100%;
	background-color: $c-white;
	background-position: top right;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("../../assets/images/pages/page-sign-in/background.jpeg");

	.sign-in {
		height: 100%;
		display: flex;

		&__container {
			width: 768px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			@include max-width($scr-sm) {
				width: 100%;
			}
		}

		&__wrapper {
			width: 80%;
		}

		&__logo {
			width: 200px;
			margin-bottom: 15px;

			img {
				width: 100%;
				display: block;
			}
		}

		&__title {
			margin-bottom: 30px;
			font-size: $text-size-xxxl;
			@extend .font-medium;

			span {
				color: $c-primary;
				@extend .font-semibold;
			}
		}

		&__label {
			font-size: $text-size-s;
			@extend .font-medium;
		}

		&__button-container {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.app-cta {
				width: 150px;
			}
		}

		&__link {
			margin: 30px 0;
			color: $c-primary;
			font-size: $text-size-s;
		}

		form {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.app-input {
				&__input {
					height: 50px;
				}
			}
		}
	}
}
