.app-table {
	width: 100%;

	.table {
		thead {
			padding: 10px;
			border-radius: 10px;
			background-color: $c-white;

			tr {
				th {
					position: relative;
					padding: 15px;
					font-size: $text-size-s;
					vertical-align: baseline;
					cursor: pointer;
					@extend .font-semibold;
				}
			}
		}

		tbody {
			border-top: 0;
			background-color: $c-white;

			tr {
				transition: 200ms ease-in-out 25ms;

				&:hover {
					background-color: $c-light-gray;

					td {
						color: $c-black;

						.table__toggle-arrow {
							stroke: $c-black;
						}
					}
				}

				td {
					padding: 15px;
					font-size: $text-size-s;
					vertical-align: middle;
					border-bottom: 1px solid $c-light-gray;
				}
			}
		}

		&__row {
			&--selected {
				background-color: $c-cyan;

				td {
					color: $c-white;
				}
			}
		}

		&__text {
			white-space: nowrap;
			text-transform: capitalize;

			&--bold {
				@extend .font-medium;
			}

			&--new-lead {
				padding: 5px 10px;
				color: #0071a2;
				border-radius: 25px;
				background-color: #bbeaff;
			}

			&--follow-up {
				padding: 5px 10px;
				color: #eeba00;
				border-radius: 25px;
				background-color: #fff6d5;
			}

			&--converted {
				padding: 5px 10px;
				color: #00a206;
				border-radius: 25px;
				background-color: #bbffbe;
			}

			&--low-rate {
				padding: 5px 10px;
				color: #a2000e;
				border-radius: 25px;
				background-color: #ffdbde;
			}
		}

		&__filter-caret {
			width: 10px;
			height: 10px;
			margin-left: 5px;
			pointer-events: none;
		}

		&__filters {
			position: absolute;
			left: 0;
			right: 0;
			padding: 0;
			margin: 10px 0 0 0;
			display: none;
			background-color: $c-white;
			box-shadow: rgba(38, 57, 77, 0.5) 0px 0px 30px -10px;

			&--visible {
				display: block;
			}
		}

		&__filter-item {
			padding: 8px 20px;
			font-size: $text-size-s;
			list-style-type: none;
			word-break: break-word;
			transition: 200ms ease-in-out 25ms;
			cursor: pointer;
			@extend .font-normal;

			&:not(:last-child) {
				border-bottom: 1px solid $c-light-gray;
			}

			&:hover {
				color: $c-white;
				background-color: $c-gray;
			}
		}

		&__edit-cta {
			width: 30px;
			height: 30px;
			border: 0;
			background-color: transparent;
		}

		&__toggle-cta {
			width: 30px;
			height: 30px;
			border: 0;
			background-color: transparent;

			&--active {
				transform: rotate(-180deg);

				.table__toggle-arrow {
					stroke: $c-white;
				}
			}
		}
	}
}
