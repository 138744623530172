.app-task-details-modal {
	min-width: 600px;

	.task-details-modal {
		border: 0;
		border-radius: 10px;

		&__header {
			border: 0;

			.btn-close {
				outline: none;
				box-shadow: none;
			}
		}

		&__list {
			padding: 0;
			margin: 0;
			display: flex;
			flex-wrap: wrap;

			li {
				width: 50%;
				padding: 15px;
				list-style-type: none;
			}
		}

		&__status {
			@extend .font-semibold;

			&--new-lead {
				color: #0071a2;
			}

			&--follow-up {
				color: #eeba00;
			}

			&--converted {
				color: #00a206;
			}

			&--low-rate {
				color: #a2000e;
			}
		}

		&__label {
			margin: 0 0 10px;
			color: #5d5e5f;
		}

		&__text {
			margin: 0 0 10px;
			@extend .font-semibold;
		}
	}
}
