.page-customer {
    padding: 40px;

    .customer {
        //
        &__header {
            display: flex;
            align-items: center;
        }

        &__back-button {
            width: 35px;
            height: 35px;
            margin-right: 15px;
            color: $c-white;
            background-color: $c-primary;
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__title {
            margin: 0;
            color: $c-light-black;
            font-size: $text-size-xl;
            @extend .font-semibold;
        }

        &__list {
            padding: 0;
            margin-top: 25px;
            display: flex;
            align-items: center;
        }

        &__item {
            width: 250px;
            padding: 15px;
            margin-right: 10px;
            border-radius: 10px;
            list-style-type: none;
            background-color: $c-white;

            &--new-lead {
                color: #0071a2;
                background-color: #bbeaff;
            }

            &--follow-up {
                color: #eeba00;
                background-color: #fff6d5;
            }

            &--converted {
                color: #00a206;
                background-color: #bbffbe;
            }

            &--low-rate {
                color: #a2000e;
                background-color: #ffdbde;
            }
        }

        &__actions {
            margin-left: auto;
            display: flex;
            align-items: center;
        }

        &__action-item {
            position: relative;
            width: 45px;
            height: 45px;
            margin: 8px;
            cursor: pointer;
            color: $c-primary;
            background-color: $c-white;
            border: 1px solid $c-primary;
            border-radius: 50px;
            list-style-type: none;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__dropdown {
            position: absolute;
            padding: 0;
            margin: 0;
            top: calc(100% + 15px);
            right: calc(100% - 50px);
            width: 200px;
            border-radius: 10px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            display: none;

            &--active {
                display: block;
            }
        }

        &__dropdown-item {
            padding: 15px;
            color: $c-gray;
            font-size: $text-size-s;
            background-color: $c-white;
            list-style-type: none;

            &:first-child {
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
            }

            &:last-child {
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            &:hover {
                color: $c-white;
                background-color: $c-dark-green;
            }
        }

        &__label {
            margin: 0 0 5px;
            color: $c-gray;
            font-size: $text-size-s;
        }

        &__text {
            margin: 0;
            word-break: break-word;
            @extend .font-semibold;
        }

        &__tabs {
            border-bottom: 0;

            .nav-link {
                margin-right: 10px;
                color: $c-black;
                padding: 15px 20px;
                background-color: darken($c-light-gray, 5%);
                border: 0;
                cursor: pointer;

                &.active {
                    border: 0;
                    color: $c-primary;
                    background-color: $c-white;
                    @extend .font-semibold;
                }
            }
        }

        &__tab {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            border-bottom-left-radius: 15px;
            background-color: $c-white;
        }

        &__tab-wrapper {
            display: flex;
            min-width: 960px;
        }

        &__content-wrapper {
            padding: 35px 0;

            &:not(:last-child) {
                border-bottom: 1px dashed darken($c-light-gray, 5%);
            }
        }

        &__form {
            width: 70%;
            padding: 20px;

            &:last-child {
                width: 30%;
                margin-left: 20px;
                background-color: darken($c-light-gray, 5%);
            }

            .app-cta {
                margin-top: 25px;
            }

            form {
                max-width: 640px;

                .app-cta {
                    margin-right: 10px;
                    width: 150px;
                }
            }
        }

        &__chart {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }

        &__field {
            margin-top: 25px;
        }

        &__form-header {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .app-cta {
                margin: 0;
                width: 125px;
                min-width: 125px;
                font-size: $text-size-s;
            }
        }

        &__notes-title {
            margin: 0;
            font-size: $text-size-m;
            @extend .font-semibold;
        }

        &__notes-list {
            padding: 0;
            height: 640px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 0px;
            }
        }

        &__note {
            width: 100%;
            margin: 0;
            padding: 15px;
            margin-bottom: 5px;
            border-radius: 5px;
            list-style-type: none;
            font-size: $text-size-s;
            background-color: $c-white;

            .customer__date {
                margin: 8px 0 0;
                font-size: $text-size-xs;
                color: lighten($c-gray, 10%);
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                span {
                    width: 50%;
                    word-break: break-word;
                    padding: 5px 10px 5px 0;

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }

        &__content {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        &__info {
            width: 33%;
            margin-bottom: 15px;
        }

        &__tasks-container {
            width: 70%;

            &:last-child {
                width: 30%;
                margin-left: 20px;
                background-color: darken($c-light-gray, 5%);
            }
        }

        &__task-header {
            padding: 20px 20px 0;
            text-align: right;

            .app-cta {
                width: 150px;
                min-width: 150px;
            }
        }

        .tasks {
            padding: 20px;
            height: 640px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 0px;
            }

            &__item {
                padding: 20px 0;
                list-style-type: none;

                &:not(:last-child) {
                    border-bottom: 1px dashed darken($c-light-gray, 5%);
                }
            }

            &__task {
                padding: 20px 0;
                display: flex;
            }

            &__column {
                flex: 1;

                &--action {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }

            &__date {
                margin: 0 0 15px;
                @extend .font-semibold;
            }

            &__status {
                @extend .font-semibold;

                &--new-lead {
                    color: #0071a2;
                }

                &--follow-up {
                    color: #eeba00;
                }

                &--converted {
                    color: #00a206;
                }

                &--low-rate {
                    color: #a2000e;
                }
            }

            &__label {
                margin: 0 0 10px;
                font-size: $text-size-s;
                color: lighten($c-gray, 10%);
            }

            &__value {
                margin: 0;
                font-size: $text-size-s;
                @extend .font-semibold;
            }

            &__icon {
                font-size: $text-size-xxl;

                &--error {
                    color: $c-red;
                }

                &--success {
                    color: $c-dark-green;
                }
            }

            &__sms-cta {
                cursor: pointer;
            }
        }
    }

    .scored-card {
        position: relative;
        height: 220px;
        width: 350px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &__bar {
            width: 100%;
        }

        &__pointer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 1px;
            transition: 0.3s linear;
        }

        &__item {
            position: relative;
        }

        &__dot {
            position: absolute;
            content: "";
            top: -15px;
            left: -7.5px;
            width: 30px;
            height: 30px;
            display: block;
            border-radius: 100px;
            background-color: $c-white;
            transition: 0.3s linear;
        }

        &__text {
            margin: 0;
            font-size: $text-size-xxxl;
            @extend .font-semibold;
        }

        &__profit-value {
            position: absolute;
            left: 50%;
            top: 85%;
            transform: translate(-50%, -50%);
        }

        &__score {
            height: 50px;
            width: 100px;
            border: 0;
            text-align: center;
            font-size: $text-size-xxxl;
            background-color: transparent;
        }

        &__footer {
            margin-top: 20px;
        }

        &__content {
            text-align: center;
        }

        &__title {
            margin: 0;

            span {
                color: #7476ed;
                @extend .font-semibold;

                &.hot {
                    color: #e56f8c;
                }

                &.warm {
                    color: #7476ed;
                }

                &.cold {
                    color: #64c2db;
                }
            }
        }

        &__description {
            margin: 0;
            font-style: italic;
            font-size: $text-size-xs;
        }
    }
}
