.app-length-of-business-input {
    width: 100%;
    min-width: 300px;
    margin: 8px 0;

    &__container {
        border-radius: 5px;
        background-color: $c-white;
        border: 1px solid darken($c-light-gray, 10%);
        display: flex;
        align-items: center;

        &--disabled {
            border: 0;
        }

        &--error {
            border: 1px solid $c-red;
        }
    }

    &__input {
        height: 40px;
        border: 0;
        outline: none;
        box-shadow: none;

        &:focus {
            box-shadow: none;
        }

        &::placeholder {
            color: $c-gray;
            font-size: $text-size-s;
        }

        &:disabled {
            border: 0;
            background-color: transparent;
        }
    }

    &__icon {
        padding: 0 0 0 15px;
        color: $c-light-gray;
        font-size: $text-size-l;
        display: flex;
        align-items: center;
    }

    &__toggle-icon {
        @extend .app-input__icon;
        padding: 0 10px 0 15px;
        cursor: pointer;
    }

    &__text {
        margin: 2.5px 0 0;
        color: $c-gray;
        font-size: $text-size-xs;

        &--error {
            color: $c-red;
        }
    }

    &__label {
        margin: 2.5px 5px;
        font-size: $text-size-xs;
        @extend .font-semibold;
    }
}
