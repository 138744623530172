.app-layout {
	height: 100vh;
	min-width: 768px;
	display: flex;

	@include max-width($scr-sm) {
		height: 100%;
	}

	&__main {
		width: 100%;
		height: 100%;
		overflow-y: hidden;
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}

	&__container {
		height: 100%;
		overflow-y: scroll;
		background-color: $c-light-gray;
		scrollbar-width: none;
		-ms-overflow-style: none;
		display: flex;
		flex-direction: column;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__footer {
		margin-top: auto;
		padding: 10px 40px;
		font-size: $text-size-xs;
		color: $c-gray;
	}
}
