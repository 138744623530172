.page-dashboard {
	padding: 40px 30px;

	.dashboard {
		//
		&__title {
			margin: 0 10px;
			color: $c-light-black;
			font-size: $text-size-xl;
			@extend .font-semibold;
		}

		&__header {
		}

		&__list {
			margin: 0;
			padding: 0;
			display: flex;
		}

		&__item {
			margin: 10px;
			padding: 15px;
			border-radius: 10px;
			background-color: $c-white;
			list-style-type: none;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex: 1;
		}

		&__content {
			padding: 5px;

			&--value {
				display: flex;
				align-items: center;
			}
		}

		&__label {
			margin: 0 0 5px;
			color: $c-gray;
			font-size: $text-size-s;
		}

		&__text {
			margin: 0;
			font-size: $text-size-xl;
			@extend .font-semibold;
		}

		&__percent {
			margin: 0 5px;
			font-size: $text-size-m;
			@extend .font-semibold;

			&--green {
				color: $c-dark-green;
			}

			&--red {
				color: $c-red;
			}
		}

		&__chart-container {
			display: flex;
			flex-wrap: wrap;
		}

		&__top-lead {
			width: calc(65% - 20px);
			min-width: 300px;
			margin: 10px;
			padding: 20px;
			border-radius: 10px;
			background-color: $c-white;
		}

		&__best-branches {
			width: calc(33% - 20px);
			min-width: 300px;
			margin: 10px;
			padding: 20px;
			border-radius: 10px;
			background-color: $c-white;
		}

		&__most-profit-product {
			width: calc(33% - 20px);
			min-width: 300px;
			margin: 10px;
			padding: 20px;
			border-radius: 10px;
			background-color: $c-white;
		}

		&__best-cross-selling {
			width: calc(33% - 20px);
			min-width: 300px;
			margin: 10px;
			padding: 20px;
			border-radius: 10px;
			background-color: $c-white;
		}
	}

	.chart {
		position: relative;

		&__title {
			font-size: $text-size-m;
			@extend .font-semibold;
		}

		&__description {
			margin: 0;
			font-size: $text-size-m;
			text-align: center;
		}

		&__legend {
			padding: 0;
			margin-bottom: 25px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		&__legend-item {
			margin: 5px;
			cursor: pointer;
			list-style-type: none;
			display: flex;
			align-items: center;
		}

		&__badge {
			margin: 0 5px 0 0;
			width: 12px;
			height: 12px;
			border-radius: 20px;
			background-color: $c-gray;
		}

		&__label {
			margin: 0 5px 0 0;
			font-size: $text-size-s;
		}

		&__profit-value {
			position: absolute;
			top: 65%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100px;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}

		&__footer {
			margin-top: 15px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}
